  .icon {
    font-size: 1.8rem;
    margin-right: 15px;
    margin-bottom: 4px;
  }

  .inputField {
    height: 40px;
  }
  
  .textArea {
    padding: 8px;
  }
  
  