.tooltip-container {
  position: relative;
  display: inline-flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically align the icon */
  margin-left: 5px;
}

.tooltip-icon {
  display: flex; /* Ensure the icon itself is flexbox-aligned */
  justify-content: center;
  align-items: center; /* Center content inside the icon */
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #007bff;
  background-color: #e9ecef;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust this to control tooltip position */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.4;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s;
}
