.mathjax-content .MathJax {
    font-size: 16px !important; /* Set the desired smaller font size */
}

.centered-table {
    border-collapse: collapse;
    margin: 0 auto; /* Center the table */
    font-size: 14px;
}

.centered-table td, .centered-table th {
    text-align: center;
    padding: 8px;
}

.centered-table tr:first-child td {
    border-bottom: 2px solid; /* Add a bottom border to the first row */
}

.centered-table tr:nth-child(even) {
    background-color: #f2f2f2;
}