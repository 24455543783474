.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 47px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background: linear-gradient(90deg, #2a2828 0%, #2e2d2d 30%, #444 75%, #666 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); /* Soft shadow */
  color: white;
}


.navbar-logo {
  position: absolute; /* Position the logo absolutely */
  left: 0.5rem;
}

/* Desktop logo */
.desktop-logo {
  display: block;  /* Show by default */
  height: 35px;
}

/* Mobile logo */
.mobile-logo {
  display: none; /* Hide by default */
  height: 30px; /* Adjust size if necessary */
}

@media (max-width: 600px) {
  /* Hide desktop logo and show mobile logo on small screens */
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }
}

.navbar-links-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  margin: 0;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px 15px;
  transition: color 0.2s, box-shadow 0.2s; /* Smooth transitions */
}

.navbar-links li a:hover {
  color: #FFD700;
  box-shadow: 0px 4px 2px -2px gray;
}

.navbar-user {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push user section to the far right */
  margin-right: 20px; /* Add right margin for wider screens */
}

.navbar-user .profile-container {
  position: relative;
}

.navbar-user .profile-picture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  margin-right: 10px; /* Add some margin to ensure it doesn't stick to the right edge */
}

.navbar-user .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateX(0%); /* Ensure it stays within the viewport */
  background-color: white;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  z-index: 1000;
}

.navbar-user .dropdown-menu::before {
  content: '';
  position: absolute;
  margin-right: 30px;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.navbar-user .dropdown-menu img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.navbar-user .dropdown-menu h3,
.navbar-user .dropdown-menu p {
  margin-bottom: 10px;
}

.navbar-user .dropdown-menu button,
.navbar-user .dropdown-menu a {
  text-decoration: none; /* Ensure no underline */
  display: block;
  width: 100%;
  margin-top: 1rem;
}

body {
  padding-top: 60px; /* Ensure the body content is not hidden behind the fixed navbar */
}

/* Hamburger menu styles */
.navbar-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  margin-right: 10px; /* Default margin for non-mobile screens */
}

/* Adjustments for smaller screens */
@media (max-width: 1400px) {
  .navbar-menu-icon {
    display: block;
    margin-right: 10px; /* Keep default margin for mobile screens */
  }

  .navbar-user {
    margin-left: auto; /* Push user section to the far right */
    margin-right: 15px; /* Add right margin for mobile screens */
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Center items vertically */
  }

  .navbar-user .profile-container {
    position: relative; /* Reset position */
  }

  .navbar-links-container {
    display: none; /* Hide links container on mobile */
  }

  .navbar-links-container.active {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: #333;
    z-index: 999; /* Ensure it stays above other content */
    padding-bottom: 1rem; /* Add padding to the bottom */
  }

  .navbar-links {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%; /* Ensure full width for links */
  }

  .navbar-links li {
    width: 100%; /* Ensure full width for list items */
  }

  .navbar-links li a {
    font-size: 1rem;
    padding: 10px; /* Add padding for better touch target */
    width: 100%; /* Ensure full width for links */
  }

  .navbar-login-mobile {
    display: flex; /* Ensure login button is visible on mobile */
    align-items: center;
  }
}

@media (min-width: 1400px) {
  .navbar-links-container {
    display: flex; /* Ensure links container is visible on desktop */
  }

  .navbar-user .profile-container {
    display: flex; /* Ensure profile container is visible on desktop */
    align-items: center;
    margin-left: 10px; /* Add margin between profile picture and hamburger icon */
  }

  .navbar-menu-icon {
    display: none; /* Hide hamburger icon on desktop */
  }

  .navbar-links li.on-mobile {
    display: none; /* Hide mobile specific items on desktop */
  }
}
