/* General Styling */
.calculator-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.button-container {
  text-align: center;
  margin-top: 30px;
}

.btn-primary {
  background-color: #6cbe47;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-primary:hover {
  background-color: #447c2c;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.oldat-title {
  text-align: center;
  font-style: bold;
}

/* Divider Styling */
.custom-divider {
  max-width: 800px !important;
  margin: 0 auto !important;
  margin-top: 20px !important;
  width: 100% !important;
  border-top: 15px solid #ccc !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;
}

/* Section Styling */
.section {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.section img {
  width: 120px;
  height: auto;
  margin: 0 auto 20px auto;
  display: block;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}

.section-description {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 auto 20px auto;
  max-width: 700px;
  text-align: center;
}

.section-description a {
  color: #1976d2;
  text-decoration: none;
  font-weight: bold;
}

.section-description a:hover {
  text-decoration: underline;
}

.section-video-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.section-video-container iframe {
  width: 100%;
  max-width: var(--video-width, 560px);
  height: calc(var(--video-width, 560px) * 9 / 16);
  border: none;
  border-radius: 5px;
}

/* Form and Results Styling */
.form-and-results {
  display: flex;
  gap: 40px;
  flex-wrap: wrap; /* Allows items to wrap into rows */
}

.form-section,
.results-section {
  flex: 1;
  min-width: 300px; /* Ensures sections have a minimum width */
}

.results-section {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #888;
  font-style: italic;
  text-align: center;
  font-size: 1rem;
  min-height: 200px;
  overflow-y: auto;
  position: relative;
  white-space: pre-wrap;
}

.results-section.results-active {
  color: #000;
  text-align: left;
  font-style: normal;
}

.results-section p {
  font-size: 14px;
  line-height: 1.2;
}

.results-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.results-section ol {
  font-size: 14px;
  line-height: 1.8;
  margin-left: -18px;
  margin-bottom: 5px;
}

.results-section ol li {
  font-size: 14px;
  margin-bottom: 5px;
}

/* Responsive Styling for Form and Results */
@media (max-width: 768px) { /*seems like the max width change doesn't work */
  .form-and-results {
    flex-direction: column; /* Stacks the sections vertically */
  }

  .form-section,
  .results-section {
    min-width: 80%; 
  }
}

/* Form Group Styling */
.form-group {
  margin-bottom: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.property-label {
  width: 80px;
  text-align: right;
  margin-top: 2px;
}

.unit-label {
  font-size: 0.9rem;
}

.input-field,
.small-input,
.large-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  height: 38px;
  font-size: 11px;
  max-width: 120px;
}

.small-input {
  width: 62px;
}

.large-input {
  max-width: 60px;
}

.middle-dot {
  font-size: 1.2rem;
  text-align: center;
}

/* Specific Fix for the Entire N Row */
.input-group-row {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
  margin-top: 2px;
}

/* Content Text Styling */
.content-text {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  padding: 5px 20px 20px 20px;
}

.tabs-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #ccc; /* Add grey line below the tabs */
}

.tabs-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tab-root {
  flex-grow: 1;
  text-align: center;
  max-width: none; /* Ensures tabs are evenly spaced */
}

@media (min-width: 960px) {
  .tabs-wrapper {
    justify-content: space-evenly;
  }
}
