.rolunk-image {
    border-radius: 50%;
    width: 100%;
    max-width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .rolunk-image {
      margin-top: 20px; /* Add spacing on smaller screens */
    }
  }
  