.section img {
  width: 100%;
  max-width: 300px;
}

.section iframe {
  width: 100%;
  max-width: 300px;
}

.section-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-wrapper {
  flex: 1.2;
  padding: 20px;
  text-align: left;
}

.text {
  flex: 1.2;
}

.text p {
  font-size: 1.1rem;
  text-align: left;
  line-height: 1.5;
}

.image {
  flex: 1;
  text-align: center;
}

.hero-title {
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
}

.hero-paragraph {
  font-size: 1.2rem;
  margin-right: 50px;
  margin-top: 40px;
  line-height: 1.8;
}

.calculator-section .section-content {
  align-items: center;
}

.calculator-section .text-wrapper {
  order: 2;
}

.calculator-title {
  text-align: left;
}

.statistics-title,
.how-to-use-title {
  text-align: left;
}

.statistics-paragraph,
.how-to-use-paragraph {
  font-size: 1.1rem;
}

.two-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.two-column .image {
  order: 1;
  margin-right: 20px;
}

.two-column .text-wrapper {
  order: 2;
  max-width: 50%;
  text-align: left;
  line-height: 1.5;
}

.two-column.reverse .image {
  order: 2;
  margin-right: 0;
  margin-left: 20px;
}

.two-column.reverse .text-wrapper {
  order: 1;
}

@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
  }

  .text-wrapper,
  .text {
    text-align: center;
  }

  .calculator-title,
  .statistics-title,
  .how-to-use-title {
    text-align: center;
  }

  .two-column .image,
  .two-column.reverse .image {
    margin: 0 auto 20px auto;
  }

  .two-column .text-wrapper,
  .two-column.reverse .text-wrapper {
    max-width: 100%;
  }
}

.crossed-out-price {
  font-size: 1.8rem;
  color: #818181;
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.crossed-out-price::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: red;
  transform: rotate(-10deg);
  transform-origin: center;
  z-index: 2;
  border-radius: 2px;
  filter: blur(1px);
  opacity: 1;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .content-wrapper .text {
    order: 1;
  }

  .content-wrapper .image {
    order: 2;
    margin-top: 20px;
    max-width: 90%;
  }

  .section img {
    max-width: 100%;
    height: auto;       /* Maintain aspect ratio */
    max-height: 300px;  /* Set the max-height for the image */
    width: auto;        /* Ensure the width adjusts accordingly */
  }
}

.hero-image {
  margin-bottom: -4px; /* Default for smaller screens */
}

@media (min-width: 768px) {
  .hero-image {
    margin-bottom: -39px; /* Apply this on larger screens */
  }
}
