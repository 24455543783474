.tabs-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    border-bottom: 1px solid #ccc; /* Add grey line below the tabs */
  }
  
  .tabs-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .tab-root {
    flex-grow: 1;
    text-align: center;
    max-width: none; /* Ensures tabs are evenly spaced */
  }
  
  @media (min-width: 960px) {
    .tabs-wrapper {
      justify-content: space-evenly;
    }
  }
  