#exerciseContainer {
    margin-top: 50px;
    margin-bottom: 120px;
  }
  
  .col-lg-6.mx-auto {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 15px;
  }
  